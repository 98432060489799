@import 'calo/variables';
@import 'calo/typography';
@import 'calo/misc';

body {
  font-family: 'Boxed', sans-serif !important;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedRegular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedMedium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedSemibold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedHeavy.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Deutschlander';
  src: url('../fonts/Deutschlander/Deutschlander.ttf');
  font-weight: 400;
}