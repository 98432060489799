.mainContainer {
  background: linear-gradient(
    192.04deg,
    rgba(37, 86, 159, 0.97) 0%,
    rgba(36, 47, 85, 0.984602) 36.98%,
    rgba(30, 45, 80, 0.97026) 63.41%,
    rgba(16, 24, 44, 0.96) 100%
  ) !important;
  min-height: 100vh !important;
  overflow: hidden;
}

.intro {
  padding-top: 0;
}
@media (max-width: 1023px) {
  .intro {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .intro {
    padding-top: 50px;
  }
}
